import * as _reactPortal2 from "@radix-ui/react-portal";
var _reactPortal = _reactPortal2;
try {
  if ("default" in _reactPortal2) _reactPortal = _reactPortal2.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import { dew as _typesDew } from "./types";
import { dew as _useKBarDew } from "./useKBar";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, {
      enumerable: true,
      get: function () {
        return m[k];
      }
    });
  } : function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
  });
  var __setModuleDefault = exports && exports.__setModuleDefault || (Object.create ? function (o, v) {
    Object.defineProperty(o, "default", {
      enumerable: true,
      value: v
    });
  } : function (o, v) {
    o["default"] = v;
  });
  var __importStar = exports && exports.__importStar || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
  };
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.KBarPortal = void 0;
  var react_portal_1 = _reactPortal;
  var React = __importStar(_react);
  var types_1 = _typesDew();
  var useKBar_1 = _useKBarDew();
  function KBarPortal(_a) {
    var children = _a.children,
      container = _a.container;
    var showing = (0, useKBar_1.useKBar)(function (state) {
      return {
        showing: state.visualState !== types_1.VisualState.hidden
      };
    }).showing;
    if (!showing) {
      return null;
    }
    return React.createElement(react_portal_1.Portal, {
      container: container
    }, children);
  }
  exports.KBarPortal = KBarPortal;
  return exports;
}