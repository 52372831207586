import { dew as _useStoreDew } from "./useStore";
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import { dew as _InternalEventsDew } from "./InternalEvents";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, {
      enumerable: true,
      get: function () {
        return m[k];
      }
    });
  } : function (o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
  });
  var __setModuleDefault = exports && exports.__setModuleDefault || (Object.create ? function (o, v) {
    Object.defineProperty(o, "default", {
      enumerable: true,
      value: v
    });
  } : function (o, v) {
    o["default"] = v;
  });
  var __importStar = exports && exports.__importStar || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
  };
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.KBarProvider = exports.KBarContext = void 0;
  var useStore_1 = _useStoreDew();
  var React = __importStar(_react);
  var InternalEvents_1 = _InternalEventsDew();
  exports.KBarContext = React.createContext({});
  var KBarProvider = function (props) {
    var contextValue = (0, useStore_1.useStore)(props);
    return React.createElement(exports.KBarContext.Provider, {
      value: contextValue
    }, React.createElement(InternalEvents_1.InternalEvents, null), props.children);
  };
  exports.KBarProvider = KBarProvider;
  return exports;
}